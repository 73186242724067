// Chakra imports
import {
  Box,
  Flex,
  Stack,
  Select,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  Grid,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';

// Assets
import axiosService from 'utils/axiosService';
import CustomTable from 'components/table/CustomTable';
import { NavLink } from 'react-router-dom/';

import { useAppContext } from 'contexts/AppContext';
import { formatNaira, formatDate } from 'utils/helper';
import LoadingSpinner from 'components/scroll/LoadingSpinner';
import CustomDateModal from 'components/modals/CustomDateModal';

const TimeRangeSelect = ({ value, onChange, customRangeLabel }) => (
  <Select value={value} onChange={onChange} minWidth="200px">
    <option value="all">All Time</option>
    <option value="last7days">Last 7 Days</option>
    <option value="last30days">Last 30 Days</option>
    <option value="custom">{customRangeLabel}</option>
  </Select>
);

const BranchSelect = ({ value, onChange, branches }) => (
  <Select value={value} onChange={onChange} minWidth="200px">
    <option value="">All Branches</option>
    {branches?.map((branch) => (
      <option key={branch.id} value={branch.id}>
        {branch?.name}
      </option>
    ))}
  </Select>
);

export default function SbReports() {
  const { branches } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [charges, setCharges] = useState([]);
  const [filterdCharges, setFilterdCharges] = useState([]);
  const [timeRange, setTimeRange] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [branch, setBranch] = useState('');
  const [isCustomDateModalOpen, setCustomDateModalOpen] = useState(false);
  const [customRangeLabel, setCustomRangeLabel] = useState('Custom Range');
  const [totalCharge, setTotalCharge] = useState(0);

  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'custom') {
      setCustomDateModalOpen(true);
    } else {
      setTimeRange(selectedValue);
    }
  };

  const handleCustomDateApply = useCallback(
    (selectedStartDate, selectedEndDate) => {
      if (selectedStartDate && selectedEndDate) {
        setCustomRangeLabel(
          `${formatDate(selectedStartDate)} to ${formatDate(selectedEndDate)}`
        );
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
        setTimeRange('custom');
      }
      setCustomDateModalOpen(false);
    },
    []
  );

  const fetchCharges = useCallback(async () => {
    setLoading(true);
    try {
      const endpoint = constructEndpoint();
      const [chargesResponse, incomeResponse] = await Promise.all([
        axiosService.get(endpoint),
        axiosService.get('/reports/contribution-incomes/sb/supperadmin'),
      ]);

      setCharges(chargesResponse.data);
      setTotalCharge(incomeResponse.data.totalCharge);
    } catch (error) {
      console.error('Error fetching charges:', error);
    } finally {
      setLoading(false);
    }
  }, [timeRange, branch, startDate, endDate]);

  const constructEndpoint = () => {
    let endpoint = `/reports/charges?reasons=Profit from SB`;

    if (timeRange !== 'all') {
      const dates = getDateRange();
      endpoint += `&startDate=${dates.start}&endDate=${dates.end}`;
    }

    if (branch) {
      endpoint += `&branchId=${branch}`;
    }

    return endpoint;
  };

  const getDateRange = () => {
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    let start = new Date();

    switch (timeRange) {
      case 'last7days':
        start.setDate(end.getDate() - 7);
        break;
      case 'last30days':
        start.setDate(end.getDate() - 30);
        break;
      case 'custom':
        return {
          start: new Date(startDate).setHours(0, 0, 0, 0),
          end: new Date(endDate).setHours(23, 59, 59, 999),
        };
      default:
        return {};
    }

    start.setHours(0, 0, 0, 0);
    return { start: start.getTime(), end: end.getTime() };
  };

  useEffect(() => {
    fetchCharges();
  }, [fetchCharges]);

  useEffect(() => {
    setFilterdCharges(filterCharges(charges, timeRange, startDate, endDate));
  }, [charges, timeRange, startDate, endDate]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => (
          <NavLink to={`/admin/user/${row.userId?.id}`}>
            <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>
              {row.userId?.firstName} {row.userId?.lastName}
            </Text>
          </NavLink>
        ),
      },
      {
        Header: 'Amount',
        accessor: (row) => formatNaira(row.amount),
      },
      {
        Header: 'Branch',
        accessor: (row) => row.branchId.name,
      },
      {
        Header: 'Date',
        accessor: (row) => formatDate(row.date),
      },
    ],
    []
  );

  return (
    <Box pt={{ base: '80px', md: '80px', xl: '80px' }}>
      <Box bg={cardBg} p={6} mb={6} borderRadius="lg" boxShadow="sm">
        <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6}>
          <Stat>
            <StatLabel fontSize="lg">Total SB Income</StatLabel>
            <StatNumber fontSize="3xl" color="blue.500">
              {formatNaira(totalCharge)}
            </StatNumber>
          </Stat>
          <Stack spacing={4}>
            <Flex gap={4} flexWrap="wrap">
              <TimeRangeSelect
                value={timeRange}
                onChange={handleSelectChange}
                customRangeLabel={customRangeLabel}
              />
              <BranchSelect
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                branches={branches}
              />
            </Flex>
          </Stack>
        </Grid>
      </Box>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box bg={cardBg} p={6} borderRadius="lg" boxShadow="sm">
          {filterdCharges?.length > 0 ? (
            <CustomTable
              columns={columns}
              data={filterdCharges}
              showPagination
            />
          ) : (
            <Text fontSize="lg" textAlign="center" color={textColor}>
              No records found!
            </Text>
          )}
        </Box>
      )}

      <CustomDateModal
        isOpen={isCustomDateModalOpen}
        onClose={() => setCustomDateModalOpen(false)}
        startDate={startDate ? new Date(startDate) : new Date()}
        endDate={endDate ? new Date(endDate) : new Date()}
        handleStartDateChange={(e) => setStartDate(e.target.value)}
        handleEndDateChange={(e) => setEndDate(e.target.value)}
        handleCustomDateApply={handleCustomDateApply}
      />
    </Box>
  );
}

// Helper function to filter charges
function filterCharges(charges, timeRange, startDate, endDate) {
  if (!charges?.length) return [];

  switch (timeRange) {
    case 'last7days':
    case 'last30days': {
      const days = timeRange === 'last7days' ? 7 : 30;
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - days);
      return charges.filter((item) => new Date(item.date) >= cutoffDate);
    }
    case 'custom': {
      if (!startDate || !endDate) return charges;
      const start = new Date(startDate);
      const end = new Date(endDate);
      return charges.filter((item) => {
        const date = new Date(item.date);
        return date >= start && date <= end;
      });
    }
    default:
      return charges;
  }
}
